<template>
	<div class="advancesList">
		<div class="index-top">
			<div class="index-title">
				<div class="title">跨端口实时转款</div>
				<div class="anniu"><el-button type="primary" @click="toteaDialog()">新增转款</el-button></div>
			</div>
			<div class="index-prompt">
			</div>
		</div>
		<div class="advancesList-main">
			<el-table :data="OrderList" border style="width: 100%">
				<el-table-column prop="out_port.advertiser_name" label="转出端口"></el-table-column>
				<el-table-column prop="out_account_id" label="转出账户ID"></el-table-column>
				<el-table-column prop="out_account_name" label="转出账户名称"></el-table-column>
				<el-table-column prop="to_port_rebates" label="转出账户返点值"></el-table-column>
				<!-- <el-table-column prop="is_stop" label="转出账户是否关停5小时"></el-table-column> -->
				<el-table-column prop="is_stop" label="是否关停账户">
					<template slot-scope="scope">
						{{scope.row.upload_img ? '否' : '是'}}
					</template>
				</el-table-column>
				<el-table-column prop="order_price" label="非赠款账户余额">
					<template slot-scope="scope">
						<div class="price">￥{{scope.row.balance}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="upload_img" label="上传的图片" width="120">
					<template slot-scope="scope">
						<div class="upload_img" @click="onimgDialog(scope.row)">
							<img :src="scope.row.file.file_url" alt="">
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="to_rmb" label="人民币"></el-table-column>
				<el-table-column prop="in_port.advertiser_name" label="转入端口"></el-table-column>
				<el-table-column prop="in_account_name" label="转入名称"></el-table-column>
				<el-table-column prop="in_account_id" label="转入账户ID"></el-table-column>
				<!-- <el-table-column prop="in_port_rebates" label="转入账户返点值"></el-table-column> -->
				<el-table-column prop="in_ad_money" label="转入广告币"></el-table-column>
				<el-table-column prop="created_at" label="下单时间">
					<template slot-scope="scope">
						{{dateFtt("yyyy-MM-dd hh:mm:ss",new Date(scope.row.created_at))}}
					</template>
				</el-table-column>
				<el-table-column prop="created_at" label="操作">
					<template slot-scope="scope">
						<el-button type="primary" @click="toteaDialog(scope.row)">编辑修改</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="lv-page"  :page-size="per_page" :pager-count="11"  @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
		</div>
		<el-dialog title="上传的图片" :visible.sync="uploadimgDialog" width="500px">
			<span><img :src="uploadimgUrl" alt=""></span>
		</el-dialog>
		<TransferDialog ref="TransferDialog"></TransferDialog>
	</div>
</template>

<script>
import TransferDialog from './transferDialog.vue'
export default {
	data () {
		return {
			OrderList:[],
			uploadimgDialog:false,
			uploadimgUrl:'',
			per_page:10,//一页多少条
			lvtotal:null,//共几条数据
		}
	},
	components:{
		TransferDialog,
	},

	mounted(){
		this.ongetOrderList(1)
	},

	methods: {
		//新增转款弹窗
		toteaDialog(data){
			this.$refs.TransferDialog.ondialogVisible(data)
		},
		// 自助充值列表
		ongetOrderList(val){
			var prame = {
				page:val,
				page_size:this.per_page,
			}
			this.$service.get(this.$api.portget_data,prame, (res)=> {
				if(res.code == '200'){
					this.OrderList = res.data.data
					this.lvtotal = res.data.total
				}
			})
		},
		//翻页
		DialogCurrentChange(val){
			this.ongetOrderList(val)
		},
		onimgDialog(row){
			this.uploadimgUrl = row.file.file_url
			this.uploadimgDialog = true
		},
		
	}
}
</script>

<style lang="scss">
.advancesList{
	.advancesList-main{
		margin-top: 20px;
		.el-table{
			.cell{
				text-align: center;
			}
			.price{
				font-size: 18px;
				font-weight: 500;
				color: #f00;
			}
			.upload_img{
				width: 100px;
				height: 60px;
				cursor: pointer;
				img{
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}
</style>
