<template>
    <el-dialog title="新增转款" :visible.sync="dialogVisible" width="860px"  class="class-dialog" :before-close='onChongxin' modal-append-to-body>
	<div class="transfer">
		<div class="index-top">
			<div class="index-title">
				<div class="anniu">
					<el-steps :active="(0 + (adv_main_id ? 20 : 0) + (out_account_name ? 20 : 0) + (out_account_id ? 20 : 0) + (Shangbiaoradio ? 10 : 0) + (in_port_id ? 10 : 0) + (in_account_name ? 10 : 0) + (in_account_id ? 10 : 0)) == 100 ? 2 : 1" simple>
						<el-step :title="'填写进度' + (0 + (adv_main_id ? 20 : 0) + (out_account_name ? 20 : 0) + (out_account_id ? 20 : 0) + (Shangbiaoradio ? 10 : 0) + (in_port_id ? 10 : 0) + (in_account_name ? 10 : 0) + (in_account_id ? 10 : 0)) + '%'" icon="el-icon-edit"></el-step>
						<el-step title="确认递交" icon="el-icon-upload"></el-step>
						<el-step title="充值成功" icon="iconfont icon-xiaohao"></el-step>
					</el-steps>
				</div>
			</div>
			<div class="index-prompt">
				<!-- <div class="text">请绑定手机号，方便与您联系</div> -->
			</div>
		</div>
		<div class="transfer-main">
			<div class="gongdan">
				<div class="dan">
					<div class="text"><span>*</span>转出端口：</div>
					<div class="inputbox">
						<el-select v-model="adv_main_id" placeholder="请选择转出端口"  @change="onAdvMainData">
							<el-option v-for="(item,key) in agentoptions" :key="key" :label="item.advertiser_name" :value="item.adv_main_id" :disabled="!item.status"></el-option>
						</el-select>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>转出账户ID：</div>
					<div class="inputbox">
						<el-input v-model="out_account_id" @blur='onOutAccoutBlur' placeholder="请输入转出账户ID"></el-input>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>转出账户名称：</div>
					<div class="inputbox">
						<el-input v-model="out_account_name" placeholder="请输入转出账户名称"></el-input>
					</div>
				</div>
				<div class="dan chang">
					<div class="text"><span>*</span>转出账户返点值：</div>
					<div class="inputbox">
						<el-input v-model="to_port_rebates" placeholder="请输入转出账户返点值"></el-input>
					</div>
				</div>
				<div class="dan chang">
					<div class="text"><span>*</span>转出账户是否关停5小时：</div>
					<div class="inputbox">
						<el-radio-group v-model="Shangbiaoradio" @change="onStopGroupChange">
							<el-radio label="0">否</el-radio>
							<el-radio label="1">是</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="dan chang">
					<div class="text"><span>*</span>转出非赠款账户余额截图：</div>
					<div class="inputbox" style="display:flex;">
						<div class="flelListimg" v-if="fileListUpload.length">
							<img :src="fileListUpload[0].url" alt="">
						</div>
						<el-upload class="upload-demo" style="display:flex;" drag :action="IndexUrl + '/api/file/upload?token=' + token" :limit="2" 
							list-type="picture-card"
							ref="upload"
							accept=".jpeg,.jpg,.png,.gif,"
							:show-file-list="false"
							:file-list="fileListUpload"
							:on-remove="onRemoveupload"
							:on-success="onSuccessupload"
							:on-change="onChangeUpload">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>非赠款账户余额：</div>
					<div class="inputbox huakuai">
						<div class="amount">￥{{balance}}</div>
						<div class="shuru"><el-input v-model="balance" placeholder="请输入内容" @change="onToRmb"></el-input></div>
						<div class="tishi">图片识别失败，直接提交即可（等待二次审核）,或者直接输入</div>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>人民币：</div>
					<div class="inputbox">
						<el-input v-model="to_rmb" placeholder="请输入人民币"></el-input>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>转入端口：</div>
					<div class="inputbox">
						<el-select v-model="in_port_id" placeholder="请选择转入端口" @change="onInPortData">
							<el-option v-for="(item,key) in agentoptions" :key="key" :label="item.advertiser_name" :value="item.adv_main_id" :disabled="!item.status"></el-option>
						</el-select>
						<span></span>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>转入账户ID：</div>
					<div class="inputbox">
						<el-input v-model="in_account_id" @blur='onInAccoutBlur' placeholder="请输入转入账户ID"></el-input>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>转入名称：</div>
					<div class="inputbox">
						<el-input v-model="in_account_name" placeholder="请输入转入名称"></el-input>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>转入账户返点值：</div>
					<div class="inputbox">
						<el-input v-model="in_port_rebates" placeholder="请输入转入账户返点值"></el-input>
					</div>
				</div>
				<div class="dan">
					<div class="text"><span>*</span>转入广告币：</div>
					<div class="inputbox">
						<el-input v-model="in_ad_money" placeholder="请输入转入广告币"></el-input>
					</div>
				</div>
				<!-- <div class="enderQueren">
					<el-button type="primary" @click="onSubmit" :disabled="delenteAnniu">确认提交</el-button>
					<el-button type="info" @click="onChongxin" :disabled="delenteAnniu">重新填写</el-button>
				</div> -->
			</div>
		</div>
	</div>
	<div slot="footer" class="dialog-footer">
		<el-button type="primary" @click="onSubmit" :disabled="delenteAnniu">确认提交</el-button>
		<el-button type="info" @click="onChongxin" :disabled="delenteAnniu">取消</el-button>
	</div>
    </el-dialog>
</template>

<script>
export default {
	data () {
		return {
			dialogVisible:false,//弹窗
			token:JSON.parse(localStorage.getItem('TZ-USER')).token,
			delenteAnniu:false,
			port_order_id:'',//编辑传入id
			agentoptions:[],//转出端口列表
			adv_main_id:'',//转出端口id
			out_account_name:'',//转出账户名称
			out_account_id:'',//转出账户ID
			Shangbiaoradio:'',//是否关停账户
			fileListUpload:[],//上传的文件列表
			file_id:'',//上传图片id
			balance:'0',//余额
			in_port_id:'',//转入端口
			in_account_name:'',//转入名称
			in_account_id:'',//转入账户ID
			origin:window.location.origin,//url链接
			in_ad_money:'',//转入广告币


			
			is_operating_type:'',// 运营类型 1 自运营 2代运营 3渠道   转出账户选择
			Ruoperating_type:'',// 运营类型 1 自运营 2代运营 3渠道   转入账户选择
			to_port_rebates:'',//转出账户返点值
			in_port_rebates:'',//转入账户返点值
			to_rmb:'',//人民币

			AdvMainData:{},//转出账户端口数据
			InPortData:{},//转入端口数据
		}
	},
	components:{
	},
	mounted(){
	},

	methods: {
		// 打开弹窗
		ondialogVisible(data){
			this.dialogVisible = true
			this.ongetAdvertiserList()
			if(data){
				this.port_order_id = data.id,//编辑传入id
				this.adv_main_id = data.out_port.id,//转出端口id
				this.out_account_name = data.out_account_name,//转出账户名称
				this.out_account_id = data.out_account_id,//转出账户ID
				this.Shangbiaoradio = data.is_stop.toString(),//是否关停账户
				this.fileListUpload[0] = data.file,//上传的文件列表
				this.file_id = data.file_id,//上传图片id
				this.balance = data.balance,//余额
				this.in_port_id = data.in_port.id,//转入端口
				this.in_account_name = data.in_account_name,//转入名称
				this.in_account_id = data.in_account_id,//转入账户ID
				this.in_ad_money = data.in_ad_money,//转入广告币

				
				this.to_port_rebates = data.to_port_rebates,//转出账户返点值
				this.in_port_rebates = data.in_port_rebates,//转入账户返点值
				this.to_rmb = data.to_rmb//人民币


				this.fileListUpload[0].uid = data.file.id
				this.fileListUpload[0].url = data.file.file_url


				this.onOutAccoutBlur()
				this.onInAccoutBlur()
			}
		},
		// 选出选择的端口传入id
		onagentoptionsinfo(id){
			var agentoptions = this.agentoptions
			var data;
			agentoptions.forEach((v,k) => {
				if(v.adv_main_id == id){
					return data = v
				}
			})
			return data
		},
		//转出账户端口选择触发
		onAdvMainData(){
			this.AdvMainData = this.onagentoptionsinfo(this.adv_main_id)
			this.onCollChenge()
		},
		//计算转出账户返点值
		onCollChenge(){
			console.log(this.adv_main_id,this.is_operating_type)
            if(this.adv_main_id == '' || this.is_operating_type == ''){
                return
            }else{
                var goo_contrary_to_port_rebates = this.AdvMainData.goo_contrary_to_port_rebates; //对私自运营
                var goo_private_port_rebates = this.AdvMainData.goo_private_port_rebates; //对私代运营
                var sto_contrary_to_port_rebates = this.AdvMainData.sto_contrary_to_port_rebates; //对公自运营
                var sto_private_port_rebates = this.AdvMainData.sto_private_port_rebates; //对公代运营、
                var channel_private_port_rebates = this.AdvMainData.channel_private_port_rebates; //对公自运营
                var channel_contrary_to_port_rebates = this.AdvMainData.channel_contrary_to_port_rebates; //对公代运营、
                var num = {
                    22: goo_private_port_rebates,
                    12: goo_contrary_to_port_rebates,
                    21: sto_private_port_rebates,
                    11: sto_contrary_to_port_rebates,
                    23: channel_private_port_rebates,
                    13: channel_contrary_to_port_rebates,
                };

                this.to_port_rebates = num[( '2' + (this.is_operating_type.toString())) * 1]
				console.log(this.AdvMainData,1111111)
				this.onToRmb()
            }
        },
		// 计算人民币
		onToRmb(){
			if(this.to_port_rebates == '' || this.balance == ''){
				return this.to_rmb = ''
			}else{
				if(this.is_operating_type == '1'){
					this.to_rmb = (this.balance / this.to_port_rebates).toFixed(2)
				}if(this.is_operating_type == '2'){
					this.to_rmb = (this.balance * this.to_port_rebates).toFixed(2)
				}if(this.is_operating_type == '3'){
					this.to_rmb = (this.balance / this.to_port_rebates).toFixed(2)
				}
				if(this.in_port_rebates != ''){
					this.onToGgb()
				}
			}
		},
		//转出账户id失去焦点触发
		onOutAccoutBlur(){
			if(this.out_account_id == ''){return}
			var param = {
				agent_account : this.out_account_id
			}
			this.$service.get(this.$api.agentAccountSearchRes,param, (res)=> {
				if(res.code == '200'){
					if(res.data != null){
						this.is_operating_type = res.data.is_operating_type
						if(!this.port_order_id){
							this.out_account_name = res.data.agent_account_name
							this.onCollChenge()
						}
					}
				}
			})
		},
		//转入账户id失去焦点触发
		onInAccoutBlur(){
			if(!this.out_account_id){return}
			var param = {
				agent_account : this.in_account_id
			}
			this.$service.get(this.$api.agentAccountSearchRes,param, (res)=> {
				if(res.code == '200'){
					if(res.data != null){
						this.Ruoperating_type = res.data.is_operating_type
						if(!this.port_order_id){
							this.in_account_name = res.data.agent_account_name
							this.onRuCollChenge()
						}
					}
					
				}
			})
		},
		//转入端口选择触发
		onInPortData(){
			this.InPortData = this.onagentoptionsinfo(this.in_port_id)
			this.onRuCollChenge()
		},
		//计算转入账户返点值
		onRuCollChenge(){
            if(this.in_port_id == '' || this.Ruoperating_type == ''){
                return
            }else{
                var goo_contrary_to_port_rebates = this.InPortData.goo_contrary_to_port_rebates; //对私自运营
                var goo_private_port_rebates = this.InPortData.goo_private_port_rebates; //对私代运营
                var sto_contrary_to_port_rebates = this.InPortData.sto_contrary_to_port_rebates; //对公自运营
                var sto_private_port_rebates = this.InPortData.sto_private_port_rebates; //对公代运营、
                var channel_private_port_rebates = this.InPortData.channel_private_port_rebates; //对公自运营
                var channel_contrary_to_port_rebates = this.InPortData.channel_contrary_to_port_rebates; //对公代运营、
                var num = {
                    22: goo_private_port_rebates,
                    12: goo_contrary_to_port_rebates,
                    21: sto_private_port_rebates,
                    11: sto_contrary_to_port_rebates,
                    23: channel_private_port_rebates,
                    13: channel_contrary_to_port_rebates,
                };

                this.in_port_rebates = num[( '2' + (this.Ruoperating_type.toString()) ) * 1]
				this.onToGgb()
            }
        },
		// 计算广告币
		onToGgb(){
			if(this.in_port_rebates == '' || this.to_rmb == ''){
				return this.in_ad_money = ''
			}else{
				if(this.Ruoperating_type == '1'){
					this.in_ad_money = (this.to_rmb * this.in_port_rebates).toFixed(2)
				}if(this.Ruoperating_type == '2'){
					this.in_ad_money = (this.to_rmb / this.in_port_rebates).toFixed(2)
				}if(this.Ruoperating_type == '3'){
					this.in_ad_money = (this.to_rmb * this.in_port_rebates).toFixed(2)
				}
			}
		},
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },
		//是否关停账户5小时
		onStopGroupChange(){
			if(this.Shangbiaoradio == '0'){ return this.$alert('您的账户关停未到5小时，请稍后再提交!', '提示', {confirmButtonText: '确定',});}
		},
		//获取代理商列表
		ongetAdvertiserList(){
			this.$service.get(this.$api.getAdvertiserList,{}, (res)=> {
				if(res.code == 200){
					this.agentoptions = res.data
				}
			})
		},
		//上传文件移出
		onRemoveupload(file, fileList){
			console.log('上传文件移出',fileList)
			this.fileListUpload = fileList
			this.file_id = ''
			this.$message({message: '文件移出成功，请重新上传文件',type: 'success'});
		},
		
		//文件上传成功
		onSuccessupload(res, file,fileList){
			if(res.code == 200){
				this.fileListUpload = fileList
				this.file_id = res.data.id
				this.ongetImageOcr()
				
				console.log(this.fileListUpload)
			}
		},
		//文件上传成功，失败 所有都会调用
		onChangeUpload(file, fileList){
			this.fileListUpload = [fileList[fileList.length - 1]]
		},
		//识别文件金额
		ongetImageOcr(){
			var prame = {
				file_id:this.file_id
			}
			this.$service.post(this.$api.getImageOcr,prame, (res)=> {
				if(res.code == '200'){
					this.balance = res.data.price
					this.onToRmb()
				}
			})
		},
		//确认提交
		onSubmit(){
			if(this.Shangbiaoradio == '0'){ return this.$alert('您的账户关停未到5小时，请稍后再提交!', '提示', {confirmButtonText: '确定',});}
			if(this.out_port_id == ''){this.$message({message: '请选择转出端口id',type: 'warning'}); return}
			if(this.out_account_name == ''){this.$message({message: '请输入转出账户名称',type: 'warning'}); return}
			if(this.out_account_id == ''){this.$message({message: '请输入转出账户ID',type: 'warning'}); return}
			if(this.Shangbiaoradio == ''){this.$message({message: '请选择是否关停账户',type: 'warning'}); return}
			if(this.file_id == ''){this.$message({message: '请上传转出账户余额截图',type: 'warning'}); return}
			if(this.balance == ''){this.$message({message: '请上传转出账户余额截图',type: 'warning'}); return}
			if(this.in_port_id == ''){this.$message({message: '请选择转入账户',type: 'warning'}); return}
			if(this.in_account_name == ''){this.$message({message: '请输入转入名称',type: 'warning'}); return}
			if(this.in_account_id == ''){this.$message({message: '请输入转入账户ID',type: 'warning'}); return}
			if(this.in_ad_money == ''){this.$message({message: '请输入转入广告币',type: 'warning'}); return}
			if(this.to_port_rebates == ''){this.$message({message: '请输入转出账户返点值',type: 'warning'}); return}
			if(this.to_rmb == ''){this.$message({message: '请输入人民币',type: 'warning'}); return}
			if(this.is_operating_type == ''){this.$message({message: '未查询到转出账户，请联系运营官绑定',type: 'warning'}); return}
			var prame = {
				out_port_id : this.adv_main_id,//转出端口id
				out_account_name : this.out_account_name,//转出账户名称
				out_account_id : this.out_account_id,//转出账户ID
				is_stop : this.Shangbiaoradio,//是否关停账户
				file_id : this.file_id,//上传图片id
				balance : this.balance,//余额
				in_port_id: this.in_port_id,//转入端口
				in_account_name : this.in_account_name,//转入名称
				in_account_id : this.in_account_id,//转入账户ID
				in_ad_money : this.in_ad_money,//转入账户ID
				to_port_rebates:this.to_port_rebates,//转出张华返点值
				in_port_rebates:this.in_port_rebates,//转入张华返点值
				to_rmb:this.to_rmb,//人民币
			}
			this.port_order_id ? prame.port_order_id = this.port_order_id : ''
			this.delenteAnniu = true
			console.log(prame)
			this.$service.post(this.$api.portCreate,prame, (res)=> {
				if(res.code == '200'){
					this.$message({message: '提交成功',type: 'success'});
					this.onChongxin()
					this.$parent.ongetOrderList(1)
				}
			})
			setTimeout(() => {
				this.delenteAnniu = false
			}, 1000);
		},
		// 重置
		//重新填写
		onChongxin(){
			this.dialogVisible = false
			this.agentoptions = [],//转出端口列表
			this.adv_main_id = '',//转出端口id
			this.out_account_name = '',//转出账户名称
			this.out_account_id = '',//转出账户ID
			this.Shangbiaoradio = '',//是否关停账户
			this.fileListUpload = [],//上传的文件列表
			this.file_id = '',//上传图片id
			this.balance = '0',//余额
			this.in_port_id = '',//转入端口
			this.in_account_name = '',//转入名称
			this.in_account_id = '',//转入账户ID
			this.origin = window.location.origin,//url链接
			this.in_ad_money = '',//转入广告币

			this.is_operating_type = '',// 运营类型 1 自运营 2代运营 3渠道   转出账户选择
			this.Ruoperating_type = '',// 运营类型 1 自运营 2代运营 3渠道   转入账户选择
			this.to_port_rebates = '',//转出账户返点值
			this.in_port_rebates = '',//转入账户返点值
			this.to_rmb = '',//人民币

			this.AdvMainData = {},//转出账户端口数据
			this.InPortData = {}//转入端口数据
		},
		
	}
}
</script>

<style lang="scss" scoped>
.transfer{
	.index-top{
		.index-title{
			align-items: center;
		}
		.el-steps{
			width: 690px;
		}
	}
	
	.transfer-main{
		margin-top: 20px;
		.gongdan{
			display: block;
			.enderQueren{
				text-align: center;
				width: 800px;
			}
			.dan{
				.huakuai{
					display: flex;
					align-items: center;
					.tishi{
						margin-left: 20px;
						color: #999;
					}
				}
				.shuru{
					width: 100px;
					margin-left: 20px;
				}
				.amount{
					min-width: 120px;
					color: #f00;
					font-size: 24px;
					font-weight: 500;
				}
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.transfer{
		.index-top{
			.index-title{
				flex-wrap: wrap;
				.anniu:nth-child(3){
					margin-top: .25rem;
					margin-left: 0;
					width: 100%;
					.el-steps{
						width: 100%;
						padding: .3rem .2rem;
					}
				}
			}
		}
		.transfer-main{
			.gongdan{
				.dan{
					.text{
						width: 2rem;
					}
					&.chang{
						.text{
							width: 3rem;
						}
					}
				}
			}
		}
	}
}
</style>
<style lang="scss">
.transfer{
	.inputbox{
		display: flex;
		.flelListimg{
			border: 1px solid #ededed;
			border-radius: 6px;
			width: 146px;
			height: 146px;
			margin-right: 10px;
			overflow: hidden;			
			img{
				width: 100%;
				height: 100%;
			}
		}
		.upload-demo{
			.el-upload {
				width: 146px;
				height: 146px;
				.el-upload-dragger{
					width: 146px;
					height: 146px;
				}
			}
		}
		
	}
	
}

@media screen and (max-width: 750px) {
.el-step.is-simple .el-step__title{
	font-size: .25rem;
}
.el-step.is-simple:not(:last-of-type) .el-step__title{
	max-width: 100%;
}
}
</style>
